<template>
 <AuthWrapper>
  <div class="auth-contents">
   <CardWrapper>
    <div align="center" class="card-body">
     <h4 class="card-body__title">
      <img :src="theme['image-large-logo']" alt=""/>
     </h4>
     <div class="card-short__content">
      <a-form :model="formState" layout="vertical" @finish="handleSubmit">
       <!--              <sdHeading as="h3">-->
       <!--                Sign in to <span class="color-secondary">Admin</span>-->
       <!--              </sdHeading>-->
       <a-form-item :rules="{required: true,message: 'Dit veld is verplicht'}" label="Gebruikersnaam of E-mailadres"
                    name="username">
        <a-input v-model:value="formState.username" type="email"/>
       </a-form-item>
       <a-form-item :rules="{required: true,message: 'Dit veld is verplicht'}" initialValue="123456" label="Wachtwoord"
                    name="password">
        <a-input
         v-model:value="formState.password"
         placeholder=""
         type="password"
        />
       </a-form-item>
       <!--              <div class="auth-form-action">-->
       <!--                <router-link class="forgot-pass-link" to="/auth/forgotPassword">-->
       <!--                  Wachtwoord vergeten?-->
       <!--                </router-link>-->
       <!--              </div>-->
       <a-form-item>
        <a-button :disabled="isLoading" :loading="isLoading" class="btn-signin" html-type="submit" size="large"
                  type="primary"> {{ isLoading ? "Laden..." : "Inloggen" }}
        </a-button>
       </a-form-item>
      </a-form>
     </div>
    </div>
   </CardWrapper>
  </div>
 </AuthWrapper>
</template>
<script>
import {computed, defineComponent, reactive, ref} from "vue";
import {useStore} from "vuex";
import {AuthWrapper, CardWrapper} from "./style";
import {theme} from "../../config/theme/themeVariables";
import {authBroadcasting, triggerGeneralNotificationSuccess, triggerOrderSignNotification} from "@/utility/utility";

const SignIn = defineComponent({
 name: "SignIn",
 components: {AuthWrapper, CardWrapper},
 methods: {
  async loginRoute() {
   if (this.type === 'LOGIN') {
    await authBroadcasting()
    window.Echo.private(`me.${JSON.parse(localStorage.getItem('tenant'))}.${JSON.parse(localStorage.getItem('id'))}`)
     .listen(".OrderSigned", (e) => {
      triggerOrderSignNotification(e)
     })
     .listen(".UserStateChanged", async () => {
      const {dispatch} = useStore();
      await dispatch('getAcl');
      await dispatch('getProfile');
      await this.$ability.update(JSON.parse(localStorage.getItem('ability')));
      await this.$router.push('/');
      this.router.go(0);
     })
     .listen(".GeneralNotification", (e) => {
      triggerGeneralNotificationSuccess(e)
     })

    this.$ability.update(JSON.parse(localStorage.getItem('ability')));
    this.$router.push('/');
   }
   if (this.type === 'PASSWORD') {
    this.$router.push({path: '/auth/password', query: {token: this.state.auth.passwordResetToken}});
   }
   if (this.type === 'TFA') {
    this.$router.push('/auth/tfa');
   }
  },
  handleSubmit() {
   this.$store.dispatch("login", {formState: this.formState, loginRoute: this.loginRoute})
  }
 },
 setup() {
  const {state} = useStore();
  const isLoading = computed(() => state.auth.loadingLogin);
  const type = computed(() => state.auth.type);
  const checked = ref(null);

  const onChange = (checked) => {
   checked.value = checked;
  };
  const formState = reactive({
   username: "",
   password: "",
  });

  return {
   isLoading,
   checked,
   onChange,
   formState,
   type,
   state,
   theme
  };
 },
});

export default SignIn;
</script>
